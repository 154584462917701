import { render, staticRenderFns } from "./createTraining.vue?vue&type=template&id=023d6e64&scoped=true&"
import script from "./createTraining.vue?vue&type=script&lang=js&"
export * from "./createTraining.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023d6e64",
  null
  
)

export default component.exports